// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-author-js": () => import("./../src/components/author.js" /* webpackChunkName: "component---src-components-author-js" */),
  "component---src-components-author-list-js": () => import("./../src/components/authorList.js" /* webpackChunkName: "component---src-components-author-list-js" */),
  "component---src-components-composer-js": () => import("./../src/components/composer.js" /* webpackChunkName: "component---src-components-composer-js" */),
  "component---src-components-composer-list-js": () => import("./../src/components/composerList.js" /* webpackChunkName: "component---src-components-composer-list-js" */),
  "component---src-components-hymn-js": () => import("./../src/components/hymn.js" /* webpackChunkName: "component---src-components-hymn-js" */),
  "component---src-components-meter-js": () => import("./../src/components/meter.js" /* webpackChunkName: "component---src-components-meter-js" */),
  "component---src-components-meter-list-js": () => import("./../src/components/meterList.js" /* webpackChunkName: "component---src-components-meter-list-js" */),
  "component---src-components-tune-js": () => import("./../src/components/tune.js" /* webpackChunkName: "component---src-components-tune-js" */),
  "component---src-components-tune-list-js": () => import("./../src/components/tuneList.js" /* webpackChunkName: "component---src-components-tune-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

